import React, { useEffect, useState } from "react";
import Block from "../../../adapters/helpers/Block";
import { mediaQueryHOC } from "../../../adapters/helpers/Hooks";
import { AnalyticsConstants } from "../../../adapters/helpers/ConstantsGA";
import { StartGuideBlockConstants } from "../../../adapters/helpers/Constants";
import StartGuideContentBlock from "../StartGuideContentBlock/StartGuideContentBlock";

const StartGuideSignupForm = (props) => {
  const block = new Block(props);
  const { isXSmall, isSmall } = props;
  const className = block.getFieldValue(StartGuideBlockConstants.className);
  const longText = block.getFieldValue(StartGuideBlockConstants.longText);
  const buttonText = block.getFieldValue(StartGuideBlockConstants.buttonText);
  const isNewSignUp = block.getFieldValue(StartGuideBlockConstants.isNewSignUp);
  const desktopButtonAsset = block.getFieldValue(
    StartGuideBlockConstants.desktopButtonAsset
  );
  const mobileButtonAsset = block.getFieldValue(
    StartGuideBlockConstants.mobileButtonAsset
  );

  const checkIsMobile = () => {
    return isXSmall || isSmall ? true : false;
  };

  if(isNewSignUp){
  return (
    <>
      {checkIsMobile() ? (
        <div className={`${className} sign-up-block-mobile`}>
          <button className="sign-up-button-mobile" >
            {longText}
          </button>
          </div>
      ) : (
        <div className={`${className} sign-up-block`}>
      <div className="sign-up-text">
          <p>{longText}</p>
      </div>
      <button className="ob-button sign-up-button">
        {buttonText}        
      </button>
    </div>
      )}
    </>
  );}
  else{
    return(
      <>
      {checkIsMobile() ? (
        <div
          className={`${className} mobile-button event_button_click`}
          data-action-detail={AnalyticsConstants.signUpEvent}
        >
          <img src={mobileButtonAsset?.fields?.file?.url} />
        </div>
      ) : (
        <div
          className={`${className} desktop-button event_button_click`}
          data-action-detail={AnalyticsConstants.signUpEvent}
        >
          <img src={desktopButtonAsset?.fields?.file?.url} />
        </div>
      )}
    </>
    )
  }
};

export default mediaQueryHOC(StartGuideSignupForm);
