import React, { useState } from 'react'
import dynamic from 'next/dynamic'
const AccordionItem = dynamic(() => import('./AccordionItem'))
const ObLink = dynamic(() => import('../ObLink/ObLink'))
const Icon = dynamic(() => import('../Icon/Icon'))
const FAQPageSEO = dynamic(() => import('../SEOschema/FAQPageSEO'))
import { ProductHighlightsConstants } from '../../adapters/helpers/Constants'
import Heading from '../Heading/Heading'

const AccordionBlock = (props) => {
    const { title, classNames, isShowMoreEnabled, accordionContent, activeIndexBoolean, accordionId, titleLevel } = props.document.fields

    const [activeIndex, setActiveIndex] = activeIndexBoolean ? useState(-1) : useState(0)
    const [showMore, setShowMore] = useState(false)

    const segregateAccordianContent = (accordionContent) => {
        const accordionQuestions = accordionContent.map((item) => item?.fields?.accordionTitle)
        const accordionAnswers = accordionContent.map((item) => item?.fields?.accordionContent)
        const accordionSchema = accordionContent.map((item) => ({
            fields: {
                name: item?.fields?.accordionTitle,
                answer: item?.fields?.accordionContent,
            },
        }))
        return { accordionQuestions, accordionAnswers, accordionSchema }
    }

    const handleClickEvent = (index, event) => {
        const ele = accordionId ? document.getElementById(`${accordionId}-ob-accordion-${index + 1}`) : document.getElementById(`ob-accordion-${index + 1}`)
        ele.style.borderBottom = 'none'
        event.target.style.textDecoration = 'none'
        index == activeIndex ? setActiveIndex(-1) : setActiveIndex(index)
    }

    const renderedAccordionTopics = accordionContent.map((topic, index) => {
        const showDescription = index === activeIndex ? 'show-description' : ''
        const fontWeightBold = index === activeIndex ? 'font-weight-bold' : ''
        const ariaExpanded = index === activeIndex ? 'true' : 'false'
        return (
            <AccordionItem
                showDescription={showDescription}
                fontWeightBold={fontWeightBold}
                ariaExpanded={ariaExpanded}
                item={topic}
                key={index}
                index={index}
                onClick={(event) => handleClickEvent(index, event)}
                docProps={props}
                accordionId={accordionId}
            />
        )
    })

    const accordionContentList = accordionContent
        ? Object.keys(accordionContent)
              .slice(0, 3)
              .map((key) => {
                  const showDescription = key == activeIndex ? 'show-description' : ''
                  const fontWeightBold = key == activeIndex ? 'font-weight-bold' : ''
                  const ariaExpanded = key == activeIndex ? 'true' : 'false'

                  return (
                      <AccordionItem
                          showDescription={showDescription}
                          fontWeightBold={fontWeightBold}
                          ariaExpanded={ariaExpanded}
                          item={accordionContent[key]}
                          index={key}
                          key={key}
                          onClick={(event) => handleClickEvent(key, event)}
                          docProps={props}
                          accordionId={accordionId}
                      />
                  )
              })
        : []

    const accordionContentMoreList = accordionContent
        ? Object.keys(accordionContent)
              .slice(3)
              .map((key) => {
                  const showDescription = key === activeIndex ? 'show-description' : ''
                  const fontWeightBold = key === activeIndex ? 'font-weight-bold' : ''
                  const ariaExpanded = key === activeIndex ? 'true' : 'false'

                  return (
                      <AccordionItem
                          showDescription={showDescription}
                          fontWeightBold={fontWeightBold}
                          ariaExpanded={ariaExpanded}
                          item={accordionContent[key]}
                          index={key}
                          key={key}
                          onClick={(event) => handleClickEvent(key, event)}
                          docProps={props}
                          accordionId={accordionId}
                      />
                  )
              })
        : []

    const showMoreButton = () => {
        let button

        if (showMore) {
            button = (
                <ObLink
                    ariaExpanded={true}
                    className='primaryGrey ob-product-highlights__see-more event_button_click'
                    dataActionDetail={ProductHighlightsConstants.seeLess}
                    tag={ProductHighlightsConstants.button}
                    ariaLabel={`${ProductHighlightsConstants.seeLess} ${ProductHighlightsConstants.questions}`}
                    onClick={handleShowMore}
                >
                    <Icon name={ProductHighlightsConstants.minus6} roundedIcon={ProductHighlightsConstants.blue} viewBox={6} />
                    {ProductHighlightsConstants.seeLess}
                    <span className='visuallyhidden'>{ProductHighlightsConstants.questions}</span>
                </ObLink>
            )
        } else {
            button = (
                <ObLink
                    ariaExpanded={false}
                    className='primaryGrey ob-product-highlights__see-more event_button_click'
                    dataActionDetail={ProductHighlightsConstants.seeMore}
                    tag={ProductHighlightsConstants.button}
                    onClick={handleShowMore}
                    ariaLabel={`${ProductHighlightsConstants.seeMore} ${ProductHighlightsConstants.questions}`}
                >
                    <Icon name={ProductHighlightsConstants.plus} roundedIcon={ProductHighlightsConstants.blue} viewBox={10} />
                    {ProductHighlightsConstants.seeMore}
                    <span className='visuallyhidden'>{ProductHighlightsConstants.questions}</span>
                </ObLink>
            )
        }

        return button
    }

    const handleShowMore = () => {
        setShowMore(!showMore)
    }
    const accordionContentLength = () => {
        // Return true if highlight is superior than 3 to display see more button
        return accordionContent && Object.keys(accordionContent).length > 3
    }

    const { accordionQuestions, accordionAnswers, accordionSchema } = segregateAccordianContent(accordionContent)

    return (
        <div className={`ob-accordion ${classNames}`}>
            {title && (
                <Heading className='ob-accordion__title' tag={titleLevel ? `h${titleLevel}` : ''}>
                    {title}
                </Heading>
            )}
            {isShowMoreEnabled ? (
                <dl className='ob-accordion__list'>
                    {accordionContentList}
                    {showMore && accordionContentMoreList}
                    {accordionContentLength() && showMoreButton()}
                </dl>
            ) : (
                <dl className='ob-accordion__list'>{renderedAccordionTopics}</dl>
            )}

            {accordionContent && <FAQPageSEO faqs={accordionSchema} question={accordionQuestions} answers={accordionAnswers} />}
        </div>
    )
}

export default AccordionBlock
