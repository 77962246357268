import OnePageScrollContext from './OnePageScrollContext';
import LayoutContext from '../Layout/LayoutContext';
import dynamic from 'next/dynamic';
const Icon = dynamic(()=>import('../Icon/Icon'))
const ProgressBar = dynamic(()=>import('../ProgressBar/ProgressBar'))
import PropTypes from 'prop-types';
import React from 'react';
import throttle from 'lodash/throttle';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'
import { OnePageScrollConstants } from '../../adapters/helpers/Constants';

const ANIMATION_TIMER = 200;
const KEY_UP = 33;
const KEY_DOWN = 34;
const DISABLED_CLASS_NAME = OnePageScrollConstants.scrollDisabled;
const CONTAINER_CLASS_NAME = OnePageScrollConstants.onePageScrollWrapper;



class ReactPageScroller extends React.Component {
    static contextType = LayoutContext;
    
    static propTypes = {
        animationFrames: PropTypes.number,
        animationTimer: PropTypes.number,
        transitionTimingFunction: PropTypes.string,
        scrollUnavailable: PropTypes.func,
        containerHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        containerWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        blockScrollUp: PropTypes.bool,
        blockScrollDown: PropTypes.bool,
        children: PropTypes.any,
        customEvent: PropTypes.object,
        isMedium:  PropTypes.bool,
        arrowLabel:  PropTypes.string,
    };

    static defaultProps = {
        animationFrames: 0,
        animationTimer: 1250,
        transitionTimingFunction: OnePageScrollConstants.easeInOut,
        containerHeight: '100%',
        containerWidth: '100%',
        blockScrollUp: false,
        blockScrollDown: false
    };

    constructor(props) {
        super(props);
        this.state = {
            componentIndex: 0,
            componentsToRenderLength: 0,
            allowVideoPlay: true,
            scrollBackwards: false,
            showPlayButton: true,
            openTranscriptModal: false,
            resetVideo: true
        };
        this.nextFrameCopy = props.arrowLabel;
        this.componentsTotalLength = 0;
        this.previousTouchMove = null;
        this._isMounted = false;
        this._isBodyScrollEnabled = true;
        this.contextProvider = null;
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.customEvent !== this.props.customEvent) {
            if (this.props.customEvent.event) {
                const pageNumber = this.props.customEvent.event.dataset.custom;
                
                if (this.context && this.context.hasContestInChildren) {
                    if (pageNumber === 'contest') {
                        this.goToPage(0);
                        this.onSliderScrollUpTop();
                    } else if (pageNumber === 'contest-thankyou'){
                        this.goToPage(0);
                        this.onContestScrollDown();
                    }
                } else if(pageNumber !== undefined) {
                    this.goToPage(parseInt(pageNumber));
                }
            }
        }

        const footer = document.querySelector('.ob-footer');
        if (footer) {
            if (this.getChildren().length - 1 === this.state.componentIndex) {
                const activeBlock = document.querySelector(OnePageScrollConstants.querySelector);
                footer.style.display = 'block';
                setTimeout(() => {
                    activeBlock?.appendChild(footer);
                });
            } else {
                footer.style.display = 'none';
            }
        }
    
        const isContestVisible = this.context && this.context.hasContestInChildren && this.context.isContestVisible;
    
        if (!isContestVisible && this.context && this.context.hasContestInChildren) {
            if (this._isMounted && this.scrolling && prevState.allowVideoPlay === this.state.allowVideoPlay && !this.state.allowVideoPlay) {
                this.previousTouchMove = null;
                this.scrolling = false;
                this.setState({
                    allowVideoPlay: true,
                    resetVideo: true
                });
            }
        }
    }

    componentDidMount = () => {
        this._isMounted = true;
        let renderChildren = this.getChildren();
    
        this.onSliderScrollUpTop = null;
        this.onContestScrollDown = null;
    
        if (this.context && this.context.hasContestInChildren) {
             this.onSliderScrollUpTop = this.context.onSliderScrollUpTop;
             this.onContestScrollDown = this.context.onContestScrollDown;
             
             if (this.context.isContestVisible) {
                 this.scrolling = true;
                 
                 this.setState({
                     allowVideoPlay: false,
                     resetVideo: false
                 });
             }
        }

        window.addEventListener(OnePageScrollConstants.resize, this.handleResizeThrottled);
        window.addEventListener(OnePageScrollConstants.hashChange, this.handleHashChange, false);

        document.ontouchmove = this.onTouchMoveEvent.bind(this);

        if(this.props.arrowLabel) {
            this.nextFrameCopy = this.props.arrowLabel;
        }

        this._pageContainer.addEventListener(OnePageScrollConstants.touchmove, this.touchMove, {passive: true});
        this._pageContainer.addEventListener(OnePageScrollConstants.keydown, this.keyPress);

        let componentsToRenderLength = 0;

        if (renderChildren && renderChildren[this.state.componentIndex] != null) {
            componentsToRenderLength++;
        } else {
            componentsToRenderLength++;
        }

        this.addNextComponent(componentsToRenderLength);

        document.documentElement.classList.add(CONTAINER_CLASS_NAME);

        if (renderChildren) {
            this.componentsTotalLength = renderChildren.length;
        }

        this.handleHashChange();
        this.getViewportRatio();
    };

    componentWillUnmount = () => {
        this._isMounted = false;

        window.removeEventListener(OnePageScrollConstants.resize, this.handleResizeThrottled);
        window.removeEventListener(OnePageScrollConstants.hashChange, this.handleHashChange, false);

        document.ontouchmove = this.onTouchMoveEventDefault.bind(this);

        this._pageContainer.removeEventListener(OnePageScrollConstants.touchmove, this.touchMove);
        this._pageContainer.removeEventListener(OnePageScrollConstants.keydown, this.keyPress);

        document.documentElement.classList.remove(CONTAINER_CLASS_NAME);
    };

    onTouchMoveEvent = (event) => {
        event.preventDefault();
    };

    onTouchMoveEventDefault = () => {
        return true;
    };

    goToPage = (number) => {
        const { componentIndex, componentsToRenderLength } = this.state;
        const children = this.getChildren();

        let newComponentsToRenderLength = componentsToRenderLength;

        if (componentIndex !== number) {
            if (children[number] && children[number] !== null) {

                this.scrolling = true;

                if (this[OnePageScrollConstants.container + (number + 2)] == null && children[number + 1] != null) {
                    newComponentsToRenderLength = number + 2;
                } else if(this[OnePageScrollConstants.container + (number + 1)] == null && children[number] != null) {
                    newComponentsToRenderLength = number + 1;
                }

                setTimeout(() => {
                    this._isMounted && this.setState({
                        componentIndex: number,
                        componentsToRenderLength: newComponentsToRenderLength
                    });
                });

                setTimeout(() => {
                    this.scrolling = false,
                    this.previousTouchMove = null
                }, this.props.animationTimer + ANIMATION_TIMER);

            } else {
                for (let i = componentsToRenderLength; i <= number; i++) {
                    newComponentsToRenderLength++;
                }

                if (children[number + 1] && children[number + 1] != null) {
                    newComponentsToRenderLength++
                }

                this.scrolling = true;
                this._isMounted && this.setState({
                    componentsToRenderLength: newComponentsToRenderLength
                }, () => {
                    setTimeout(() => {
                        this._isMounted && this.setState({
                            componentIndex: number
                        });
                    });

                    setTimeout(() => {
                        this.scrolling = false;
                            this.previousTouchMove = null
                    }, this.props.animationTimer + ANIMATION_TIMER);
                });
            }
        }
    };

    handleResizeThrottled = throttle(this.handleResize.bind(this), 100);

    handleHashChange = () => {
        if (window?.location?.hash) {
            let anchor = window.location.hash;
            this.goToHash(anchor);
        }
    };

    goToHash = (anchor) => {
        let renderChildren = this.getChildren();

        let targetSlide = renderChildren.findIndex(child => {
            return child?.props?.document?.fields?.anchorId === anchor.substr(1);
        });

        if(targetSlide >= 0) {
            this.goToPage(targetSlide);
        }
    };

    handleResize() {
        this.getViewportRatio();
        this.forceUpdate();
    }

    getViewportRatio = () => {
        let windowWidth = window.innerWidth;
        let windowHeight = window.innerHeight;

        this._isMounted && this.setState({
            viewportRatio: windowWidth / windowHeight
        })
    }

    getChildren = () => {
        let renderChildren = this.props.children;

        if(renderChildren?.props?.children) {
            renderChildren = renderChildren.props.children;
        }

        return renderChildren;
    };

    render() {
        const {containerHeight, containerWidth } = this.props;
        
        this.contextProvider = {
            toggleVideoPlay: () => {
                this._isMounted && this.setState({
                    allowVideoPlay: !this.state.allowVideoPlay,
                    resetVideo: false
                });
            },
            setVideoResetState: () => {
                this._isMounted && this.setState({
                    resetVideo: true
                });
            },

            forceVideoReset: () => {
                this._isMounted && this.setState({
                    allowVideoPlay: false,
                    resetVideo: false
                });

                setTimeout(() => {
                    this._isMounted && this.setState({
                        allowVideoPlay: true,
                        resetVideo: true
                    });
                }, 10);
            },
            setPlayButton: (state) => {
                this._isMounted && this.setState({
                    showPlayButton: state
                });
            },
            setTranscriptModal: (state) => {
                this._isMounted && this.setState({
                    openTranscriptModal: state,
                    allowVideoPlay: !state,
                    resetVideo: false
                });
            },
            openTranscriptModal: this.state.openTranscriptModal,
            viewportRatio: this.state.viewportRatio,
            showPlayButton: this.state.showPlayButton,
            resetVideo: this.state.resetVideo,
            allowVideoPlay: this.state.allowVideoPlay,
            currentIndex: this.state.componentIndex,
            videoEnded: (goToNextSlide) => {
                this._isMounted && this.setState({
                    showPlayButton: false
                });

                if(goToNextSlide) {
                    this.scrollWindowDown();
                }
            }
        };

        return (
            <OnePageScrollContext.Provider
                value={this.contextProvider}>
                <ProgressBar
                    currentIndex={this.state.componentIndex}
                    componentsTotalLength={this.componentsTotalLength}/>

                <div ref={c => this._pageContainer = c}
                     className={`scroller-frame scroller-frame-${this.state.componentIndex} ${this.state.scrollBackwards ? 'scroll-backwards' : ''}`}
                     aria-live={OnePageScrollConstants.polite}
                     onWheel={this.wheelScroll}
                     style={{
                         height: containerHeight,
                         width: containerWidth,
                         position: 'relative'
                     }}>
                    {this.setRenderComponents()}
                </div>
            </OnePageScrollContext.Provider>
        )
    }

    disableScroll = () => {
        if (this._isBodyScrollEnabled) {
            this._isBodyScrollEnabled = false;
            window.scrollTo({
                left: 0,
                top: 0,
                behavior: OnePageScrollConstants.smooth
            });
            document.body.classList.add(DISABLED_CLASS_NAME);
            document.documentElement.classList.add(DISABLED_CLASS_NAME);
        }
    };

    enableScroll = () => {
        if (!this._isBodyScrollEnabled) {
            this._isBodyScrollEnabled = true;
            document.body.classList.remove(DISABLED_CLASS_NAME);
            document.documentElement.classList.remove(DISABLED_CLASS_NAME);
        }
    };

    wheelScroll = (event) => {
        if (event.deltaY < 0) {
            this.scrollWindowUp();
        } else {
            this.scrollWindowDown();
        }
    };

    touchMove = (event) => {
        const canTouchMove = this.previousTouchMove !== null && (!this.context || (this.context && !this.context.isContestVisible));
        if (canTouchMove) {
            if (event.touches[0].clientY > this.previousTouchMove) {
                this.scrollWindowUp();
            } else {
                this.scrollWindowDown();
            }
        } else {
            this.previousTouchMove = event.touches[0].clientY;
        }
    };

    keyPress = (event) => {
        if (event.keyCode === KEY_UP) {
            this.scrollWindowUp();
        }
        if (event.keyCode === KEY_DOWN) {
            this.scrollWindowDown();
        }
    };

    addNextComponent = (componentsToRenderOnMountLength) => {
        let componentsToRenderLength = 0;
        let renderChildren = this.getChildren();

        if (componentsToRenderOnMountLength != null) {
            componentsToRenderLength = componentsToRenderOnMountLength;
        }

        componentsToRenderLength = Math.max(componentsToRenderLength, this.state.componentsToRenderLength);

        if (componentsToRenderLength <= this.state.componentIndex + 1) {
            if (renderChildren && renderChildren[this.state.componentIndex + 1] != null) {
                componentsToRenderLength++;
            }
        }

        if (this._isMounted) {
            this.setState({
                componentsToRenderLength
            });
        }
    };

    setRenderComponents = () => {
        const newComponentsToRender = [];

        let renderChildren = this.getChildren();

        for (let i = 0; i < this.state.componentsToRenderLength; i++) {
            if (renderChildren && renderChildren[i] != null) {
                let classNames = renderChildren[i].props?.document?.fields?.classNames;
                let darkArrow = classNames?.includes('dark-arrow');

                newComponentsToRender.push(
                    <div className={`${i > this.state.componentIndex ? 'pre-step' : 'step-' + (this.state.componentIndex - i + 1)} scroller-content scroller-content-` + i}
                         key={i} ref={c => this[OnePageScrollConstants.container + i] = c}
                         aria-hidden={i !== this.state.componentIndex}
                         data-is-active={i === this.state.componentIndex}
                         style={{ height: '100%', width: '100%' }}>
                        {(this.state.componentIndex - i < 5) &&
                            renderChildren[i]
                        }
                        {(this.state.componentIndex === i && i !== (this.componentsTotalLength - 1)) &&
                            <button className={'ob-one-page-scroll-arrow event_button_click'}
                                    aria-label={this.nextFrameCopy}
                                    data-action-detail={OnePageScrollConstants.scrollDown}
                                    onClick={() => this.scrollWindowDown()}>
                                <Icon name={OnePageScrollConstants.thinChevronDown} color={darkArrow ? '#3D3D41' : '#ffffff'} size={ this.props.isMedium ? '4' : '5.8'} />
                            </button>
                        }
                    </div>
                );
            } else {
                break;
            }
        }

        return newComponentsToRender;
    };

    scrollWindowUp = () => {
        if (!this.scrolling && !this.props.blockScrollUp) {

            if (this[OnePageScrollConstants.container + (this.state.componentIndex - 1)] && this[OnePageScrollConstants.container + (this.state.componentIndex - 1)] != null && !document.body.classList.contains('ReactModal__Body--open')) {
                // This will make sure that the user will be able to scroll back up on the last screen,
                // but allows a scrolling buffer so that the window doesn't scroll back up instantly.
                const activeBlock = document.querySelector(OnePageScrollConstants.querySelector);
                if (activeBlock.scrollTop > 0) {
                    return;
                }

                this.disableScroll();
                this.scrolling = true;

                setTimeout(() => {
                    this._isMounted && this.setState((prevState) => ({
                        componentIndex: prevState.componentIndex - 1,
                        scrollBackwards: true,
                        openTranscriptModal: false
                    }));
                });

                setTimeout(() => {
                    this.scrolling = false;
                    this.previousTouchMove = null;
                }, this.props.animationTimer + ANIMATION_TIMER);
            } else {
                this.enableScroll();
                if (this.props.scrollUnavailable) {
                    this.props.scrollUnavailable();
                }
                if (this.onSliderScrollUpTop) {
                    this.onSliderScrollUpTop();
                }
            }
        }
    };

    scrollWindowDown = () => {
        if (!this.scrolling && !this.props.blockScrollDown) {

            if (this[OnePageScrollConstants.container + (this.state.componentIndex + 1)] && this[OnePageScrollConstants.container + (this.state.componentIndex + 1)] != null && !document.body.classList.contains('ReactModal__Body--open')) {
                this.disableScroll();
                this.scrolling = true;

                setTimeout(() => {
                    this._isMounted && this.setState((prevState) => ({
                        componentIndex: prevState.componentIndex + 1,
                        scrollBackwards: false,
                        openTranscriptModal: false
                    }));
                    this.addNextComponent();
                });

                setTimeout(() => {
                    this.scrolling = false;
                    this.previousTouchMove = null;
                }, this.props.animationTimer + ANIMATION_TIMER);

            } else {
                this.enableScroll();
                if (this.props.scrollUnavailable) {
                    this.props.scrollUnavailable();
                }
                if (this.getChildren().length - 1 === this.state.componentIndex) {
                    const activeBlock = document.querySelector(OnePageScrollConstants.querySelector);
                    activeBlock.style.overflowY = 'auto';
                }
                if (this.onContestScrollDown) {
                    this.onContestScrollDown();
                }
            }
        }
    };
}

export default mediaQueryHOC(ReactPageScroller);
// This export is for unit testing (do not remove) :
export const OnePageScroll = ReactPageScroller;
