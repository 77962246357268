import React, { useRef, useState, useEffect, Fragment } from 'react'
import { mediaQueryHOC } from '../../../adapters/helpers/Hooks'
import Block from '../../../adapters/helpers/Block'
import Image from '../../Image/Image'
import { StartGuideBlockConstants, StartGuideShuffleBlockConstant, Constants } from '../../../adapters/helpers/Constants'
import ResponsiveImage from '../../ResponsiveImage/ResponsiveImage'
import Slider from 'react-slick'
import { AnalyticsConstants } from '../../../adapters/helpers/ConstantsGA'
import { addRemainder } from '../../../adapters/helpers/Utils'
import Modal from 'react-modal'
import Icon from '../../Icon/Icon'
import BeforeAfterImage from '../../BeforeAfterImage/BeforeAfterImage'

const StartGuideShuffleBlock = (props) => {
  const block = new Block(props)
  const description = block.getFieldValue(StartGuideBlockConstants.description)
  const className = block.getFieldValue(StartGuideBlockConstants.className)
  const cards = block.getFieldValue(StartGuideBlockConstants.cards)
  const desktopBackgroundAsset = block.getFieldValue(
    StartGuideBlockConstants?.backgroundAsset
  )
  const mobileBackgroundAsset = block.getFieldValue(
    StartGuideBlockConstants?.mobileBackgroundAsset
  )
  const isVerticalCarousel = block.getFieldValue(
    StartGuideBlockConstants?.isVerticalCarousel
  )
  const VerticalCarouselDescription = block.getFieldValue(
    StartGuideBlockConstants?.verticalCarouselDescription
  )
  const verticalCarouselCtaLabel = block.getFieldValue(
    StartGuideBlockConstants?.verticalCarouselCtaLabel
  )
  const carouselBackground = block.getFieldValue(
    StartGuideBlockConstants.carouselBackground
  )
  const carouselForeground = block.getFieldValue(
    StartGuideBlockConstants.carouselForeground
  )
  const carouselItems = block.getFieldValue(
    StartGuideBlockConstants.carouselItems
  )
  const isAnimationCards = block.getFieldValue(
    StartGuideBlockConstants.isAnimationCards
  )
  const popupContent = block.getFieldValue(
    StartGuideShuffleBlockConstant.popupContent
  )
  const [showRemainderClasses, setShowRemainderClasses] = useState(false)
  const anchorId = block.getFieldValue(StartGuideBlockConstants.anchorId)
  const [sliderIndex, setSliderIndex] = useState(1)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState( false )
  const [blinkerIndex, setBlinkerIndex] = useState(0)
  const { isXSmall, isSmall, isMedium, isXLarge, languageCode } = props
  const sliderRef = useRef(null)
  const imageRefAnimation = useRef(null)

  const baseSettings = {
    arrows: false,
    slidesToScroll: 1,
    infinite: true,
    lazyLoad: false,
    autoplay: false,
    dots: false,
    centerMode: true,
  }

  const carouselSettings = {
    ...baseSettings,
    slidesToShow: 3,
    draggable: false,
  }
  const mobileCarouselSettings = {
    ...baseSettings,
    slidesToShow: 2.3,
    draggable: true,
    centerPadding: '50px',
  }

  const customStyleSaveSettingsModal = {
    overlay: {
      position: 'fixed',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    content: {
      border: 'none',
      top: '50%',
      right: 'auto',
      left: '50%',
      bottom: 'auto',
      padding: '0',  
      transform: 'translate(-50%, -50%)',
      maxWidth: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      width: isXSmall || isSmall ? '90%' : '60%',
      'border-radius': '0px',
    },
  }

  const isDivInViewPort = (element, addition) => {
    const ele = document.querySelector(element)
    if (!ele) {
      return false
    }
    const rect = ele.getBoundingClientRect()
    const viewPortHeight = window.innerHeight
    return rect.top + addition < viewPortHeight && rect.bottom > 0
  }

  useEffect(() => {
    const viewPortValue = 0
    const viewPortRatio = 800
    let targetEle = null
    const handleScrollAnimation = () => {
      targetEle = isDivInViewPort(
        '#start-guide-shuffle-block-second',
        viewPortValue
      )
      if (targetEle) {
        const ele = document.getElementById('start-guide-shuffle-block-second')
        const secEle = ele.getBoundingClientRect()
        const viewPortHeight = window.innerHeight
        const visibleHeight =
          secEle.height > viewPortHeight ? viewPortHeight : secEle.height
        const intersectionRatio = visibleHeight - Math.max(0, secEle.top)
        if (imageRefAnimation && imageRefAnimation.current) {
          imageRefAnimation.current.style.visibility =
            intersectionRatio >= viewPortRatio ? 'hidden' : 'visible'
          imageRefAnimation.current.style.transform = `translateY(${intersectionRatio}px)`
        }
      }
    }
    window.addEventListener('scroll', handleScrollAnimation)
    return () => window.removeEventListener('scroll', handleScrollAnimation)
  }, [])

  useEffect(() => {
    const addScrollClass = (secondBlock, blockElem, classNam) => {
      const windowHeight = window.innerHeight
      const elementTop = secondBlock?.getBoundingClientRect().top
      const elementVisible = 50

      if (elementTop < windowHeight - elementVisible) {
        blockElem?.classList?.add(classNam)
      } else {
        blockElem?.classList?.remove(classNam)
      }
    }

    const srollContent = () => {
      const rightPressureStepImg = document.querySelector(
        '.use-right-pressure .start-guide-animation-asset'
      )
      const rightPressureStep = document.querySelector(
        '.use-right-pressure .start-guide-text-content'
      )

      addScrollClass(
        rightPressureStepImg,
        rightPressureStep,
        'activeAnimReplacing'
      )
    }
    window.addEventListener('scroll', srollContent)
  }, [])

  const checkIsMobile = () => {
    return isXSmall || isSmall ? true : false
  }

  const blink = ( ele ) => {
    ele.style.display = 'block'
    setTimeout( () => {
      ele.style.display = 'none'
    }, 400)
  }

  const handleBlinkerNew = () => {
    const blinkerEle = document.getElementsByClassName( 'blink-container' )[0]
    if ( blinkerIndex < 3 ) {
      switch ( blinkerIndex ) {
        case 0:
          blink( blinkerEle )
          break
        case 1:
          blink( blinkerEle )
          setTimeout( () => {
            blink( blinkerEle )
          }, 700 )
          break
        case 2:
          blink( blinkerEle )
          setTimeout( () => {
            blink( blinkerEle )
            setTimeout( () => {
              blink( blinkerEle )
            }, 700 )
          }, 700 )
        
          break
        default:
          setBlinkerIndex( 0 )
          blink( blinkerEle )
      }
      setBlinkerIndex(blinkerIndex + 1)
    } else {
      setBlinkerIndex( 1 )
      blink( blinkerEle )
    }
  }

  const handleSliderButtonClick = () => {
    sliderRef.current.slickNext()
    handleBlinkerNew()
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleCarouselItemClick = (clickedIndex) => {
    if (clickedIndex === currentSlideIndex) {
      sliderRef.current.slickNext()
    } else if (clickedIndex > currentSlideIndex) {
      sliderRef.current.slickNext()
    } else {
      sliderRef.current.slickPrev()
    }
  }

  const addRemainderClass = () => {
    setShowRemainderClasses(!showRemainderClasses)
  }

  useEffect(() => {
    if (sliderRef.current) {
      setCurrentSlideIndex(sliderRef.current.innerSlider.state.currentSlide)
    }
  }, [currentSlideIndex])
  const saveSettings = () => {
    setIsModalOpen(!isModalOpen)
  }
  const onClickBtnFunctionMap = {
    saveSettings: saveSettings
  }
  const renderTextContent = (option) => {
    return (
      <div className='start-guide-text-container'>
        {option.secondaryImage && (
          <Image
            className={'start-guide-secondary-image'}
            image={option.secondaryImage}
          />
        )}
        <div className='start-guide-text-content'>
          <h2
            className='start-guide-header'
            dangerouslySetInnerHTML={{ __html: option.title }}
          />
          {option?.subTitle && <h4
            className='start-guide-subtitle'
            dangerouslySetInnerHTML={{ __html: option.subTitle }}
          />}
          <div className='start-guide-subtext-container'>
            <p
              className='start-guide-subtext'
              dangerouslySetInnerHTML={{ __html: option.description }}
            />
            {option?.ctaButton?.CTAList?.map((item, index) => {
              return (item?.CTALink ? <a
                  className={`${option?.ctaCustomEventClass} start-guide-cta-link`}
                  href={`${option.ctaLink}`}
                  target={'_blank'}
                  data-action-detail={option?.ctaLabel}
                  aria-label={option?.ctaLabel}
                >
                  <p>{option.ctaLabel}</p>
              </a> : <button onClick={onClickBtnFunctionMap[item?.onClickFunctionName]}
                  className={item?.ctaCustomEventClass}
                  data-action-detail={option?.ctaLabel}
                  aria-label={option?.ctaLabel}><span>
                  </span> {item?.CTALabel}</button>)
            })}
          </div>
          {option.ctaLabel &&
            (option.ctaMode == 'calender' ? (
              <a
                className={`${option?.ctaCustomEventClass} start-guide-cta-link`}
                target={'_blank'}
                onClick={addRemainderClass}
                data-action-detail={option?.ctaLabel}
                aria-label={option?.ctaLabel}
              >
                <p>{option.ctaLabel}</p>
              </a>
            ) : (
              <a
                className={`${option?.ctaCustomEventClass} start-guide-cta-link`}
                href={`${option.ctaLink}`}
                target={'_blank'}
                data-action-detail={option?.ctaLabel}
                aria-label={option?.ctaLabel}
              >
                <p>{option.ctaLabel}</p>
              </a>
            ))}
          {option?.remainderCalender && (
            <div className='select-calender'>
              <div
                className={`calender-option-list-section ${
                  showRemainderClasses ? 'd-block' : 'd-none'
                }`}
              >
                <button
              className='event_close_window ob-modal-close gum-bleed-close-button'
              onClick={addRemainderClass}
            >
              <Icon name={Constants.close} size='2.4' color='#FFFFFF' />
            </button>
                <p className='calender-title'>
                  {option?.remainderCalender?.fields?.remainderListTitle}
                </p>

                {option?.remainderCalender?.fields?.remainderList.map(
                  (cardOption,index) => {
                    return (
                      <div
                        key={index}
                        className={`${cardOption?.fields?.ctaCustomEventClass} calender-content`}
                        onClick={() =>
                          addRemainder(
                            option?.remainderCalender?.fields,
                            cardOption?.fields
                          )
                        }
                        data-action-detail={`${cardOption?.fields?.type} ${AnalyticsConstants.calender}`}
                      >
                        <span className='calender-img'>
                          <Image image={cardOption?.fields?.icon} />
                        </span>
                        <span className='calender-option-list'>
                          {cardOption?.fields?.type}
                        </span>
                      </div>
                    )
                  }
                )}
              </div>
            </div>
          )}
          {option?.descriptionBelowCta &&
              <p
              className='start-guide-text-below-cta'
              dangerouslySetInnerHTML={{ __html: option.descriptionBelowCta }}
            />
          }
          {isVerticalCarousel && checkIsMobile() && (
            <div className='slider-container'>
              <Slider
                ref={sliderRef}
                {...mobileCarouselSettings}
                beforeChange={(current, next) => {
                  const corouselLngth = carouselItems.length
                  setCurrentSlideIndex(next)
                  setSliderIndex(next + 1 == corouselLngth ? 0 : next + 1)
                }}
              >
                {carouselItems.map((item,i) => {
                  return (
                    <div className='carousel-item' key={i}>
                      <Image image={item?.fields?.icons} />
                      <div className='carousel-item-text'>
                        <p className='text-header'>{item?.fields?.title}</p>
                        <p className='text-description'>
                          {item?.fields?.description}
                        </p>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          )}
          {option.video ? (
            <>
              {new Block(option.video).renderBlockFromDocument(option.video)}{' '}
              <h3
                className='videoDescription'
                dangerouslySetInnerHTML={{ __html: option?.videoDescription }}
              />
            </>
          ) : (
            option.videoThumbnail && (
              <div className='ob-carousel-tab-content-conatiner-image'>
                <ResponsiveImage
                  document={option.videoThumbnail}
                ></ResponsiveImage>
              </div>
            )
          )}
        </div>
        {option?.dragSliderReference && <BeforeAfterImage afterImage={option?.dragSliderReference?.fields?.afterImage} beforeImage={option?.dragSliderReference?.fields?.beforeImage}/>}
      </div>
    )
  }

  const renderImageContent = (option) => {
    return (
      <div className='image-container'>
        {checkIsMobile() ? (
          <Fragment>
            {isVerticalCarousel && (
              <div className='vertical-carousel-container'>
                <div className='blink-container' style={{ display: 'none' }}/>
                <Image className={'bg-img'} image={carouselBackground}>
                  <button
                    onClick={() => handleSliderButtonClick()}
                    className='event_button_click'
                    data-action-detail={`${carouselItems && carouselItems[sliderIndex]?.fields?.title}`}
                    aria-label={`${carouselItems && carouselItems[sliderIndex]?.fields?.title}`}
                  >
                    <Image
                      className={'fg-img vertical-carousel-button'}
                      image={carouselForeground}
                    >
                      <p>{StartGuideBlockConstants.cycleSettingsButton}</p>
                    </Image>
                  </button>
                </Image>
                {VerticalCarouselDescription &&
                <p
                  className='vertical-carousel-container-disclaimer'
                  dangerouslySetInnerHTML={{ __html: VerticalCarouselDescription }}
                />
                }
              </div>
            )}
            {!isVerticalCarousel && (
              <>
              <Image
                className={'start-guide-animation-asset'}
                image={option.mobileAnimationAsset}
              />
              {option?.animationImageDescription && 
                <h3
                  className='animationDescription-mobile'
                  dangerouslySetInnerHTML={{ __html: option?.animationImageDescription }}
                />}
                </>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {isVerticalCarousel && (
              <div className='vertical-carousel-container'>
                <Image className={'bg-img'} image={carouselBackground} />
                <div className='blink-container'/>
                <button
                  onClick={() => handleSliderButtonClick()}
                  className='event_button_click'
                  data-action-detail={`${carouselItems && carouselItems[sliderIndex]?.fields?.title}`}
                  aria-label={`${carouselItems && carouselItems[sliderIndex]?.fields?.title}`}
                >
                  <Image
                    className={'fg-img vertical-carousel-button'}
                    image={carouselForeground}
                  >
                    <p>{StartGuideBlockConstants.cycleSettingsButton}</p>
                  </Image>
                </button>
                <div className='slider-container'>
                  <Slider
                    ref={sliderRef}
                    {...carouselSettings}
                    beforeChange={(current, next) => {
                      const corouselLngth = carouselItems.length
                      setCurrentSlideIndex(next)
                      setSliderIndex(next + 1 == corouselLngth ? 0 : next + 1)
                    }}
                  >
                    {carouselItems?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className='carousel-item'
                          item-index={currentSlideIndex}
                          onClick={() => handleCarouselItemClick(index)}
                        >
                          <Image image={item?.fields?.icons} />
                          <div className='carousel-item-text'>
                            <p className='text-header'>{item?.fields?.title}</p>
                            <p className='text-description'>
                              {item?.fields?.description}
                            </p>
                          </div>
                        </div>
                      )
                    })}
                  </Slider>
                </div>
              </div>
            )}
            {!isVerticalCarousel && (
              <>
              <Image
                className={'start-guide-animation-asset'}
                image={option.animationAsset}

              />
              {option?.animationImageDescription && 
              <h3
                className='animationDescription'
                dangerouslySetInnerHTML={{ __html: option?.animationImageDescription }}
              />}
              </>
            )}
          </Fragment>
        )}
      </div>
    )
  }

  const renderShuffleBlock = (option) => {
    const isLeft = option.animationImagePosition==='Left'
    return(
      <>
        <p className='description'>{description}</p>
        {isLeft ? renderImageContent(option) : renderTextContent(option)}
        {isLeft ? renderTextContent(option) : renderImageContent(option)}
      </>
    )
  }

  const renderScreenLayout = (option) => {
    return (
      <div className={`start-guide-shuffle-block ${option.className}`}>
        <Image
          className={'start-guide-background'}
          image={checkIsMobile()?mobileBackgroundAsset:desktopBackgroundAsset}
        />
        <div className={'start-guide-content-container'}>
          {renderShuffleBlock(option)}
        </div>
      </div>
    )
  }

  // Animation Blocks
  const renderAnimationLayout = () => {
    return (
      <>
        {cards && cards?.[0] && (
          <div className={`start-guide-shuffle-block ${cards?.[0]?.fields.className}`}>
            <Image
              className={'start-guide-background'}
              image={
                checkIsMobile() ? mobileBackgroundAsset : desktopBackgroundAsset
              }
            />

            <div
              className={'start-guide-content-container'}
              id='start-guide-content-container-first'
            >
              {cards?.[0]?.fields.animationImagePosition ===
                StartGuideBlockConstants.left && (
                <div>
                  {checkIsMobile() ? (
                    <div className='ob-mainAsset-wrapper start-guide-animation-asset'>
                      <img
                        className='ob-mainAsset-wrapper-img'
                        src={
                          cards?.[0]?.fields.mobileAnimationAsset?.fields
                            ?.assetId
                        }
                      />
                    </div>
                  ) : (
                    <Image
                      className={'start-guide-animation-asset'}
                      image={cards?.[0]?.fields.animationAsset}
                    />
                  )}
                </div>
              )}

              {renderTextContent(cards?.[0]?.fields)}

              {cards?.[0]?.fields.animationImagePosition ===
                StartGuideBlockConstants.right && (
                <div
                  className='ob-contentBlock-text-image'
                  ref={imageRefAnimation}
                >
                  {checkIsMobile() ? (
                    <div className='ob-mainAsset-wrapper start-guide-animation-asset first-animation'>
                      <img
                        className='ob-mainAsset-wrapper-img'
                        src={
                          cards?.[0]?.fields.mobileAnimationAsset?.fields
                            ?.asset?.fields?.file?.url
                        }
                        alt={
                          cards?.[0]?.fields.mobileAnimationAsset?.fields
                            ?.alternateText
                        }
                      />
                    </div>
                  ) : (
                    <Image
                      className={'start-guide-animation-asset first-animation'}
                      image={cards?.[0]?.fields.animationAsset}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        <div>
          {cards && cards?.[1] && (
            <div
              className={`start-guide-shuffle-block ${cards?.[1]?.fields.className}`}
              id='start-guide-shuffle-block-second'
            >
              <Image
                className={'start-guide-background'}
                image={
                  checkIsMobile()
                    ? mobileBackgroundAsset
                    : desktopBackgroundAsset
                }
              />

              <div className={'start-guide-content-container'}>
                {cards?.[1]?.fields.animationImagePosition ===
                  StartGuideBlockConstants.left && (
                  <div id='start-guide-shuffle-block-img-second'>
                    {checkIsMobile() ? (
                      <div className='ob-mainAsset-wrapper start-guide-animation-asset'>
                        <img
                          className='ob-mainAsset-wrapper-img'
                          src={
                            cards?.[1]?.fields.mobileAnimationAsset?.fields
                              ?.assetId
                          }
                          alt={
                            cards?.[1]?.fields.mobileAnimationAsset?.fields
                              ?.alternateText
                          }
                        />
                      </div>
                    ) : (
                      <Image
                        className={'start-guide-animation-asset'}
                        image={cards?.[1]?.fields.animationAsset}
                      />
                    )}
                  </div>
                )}

                <div id={'start-guide-shuffle-block-second-txt'}>
                  {renderTextContent(cards?.[1]?.fields)}

                  {cards?.[1]?.fields.animationImagePosition ===
                    StartGuideBlockConstants.right && (
                    <div>
                      {checkIsMobile() ? (
                        <div className='ob-mainAsset-wrapper start-guide-animation-asset'>
                          <img
                            className='ob-mainAsset-wrapper-img'
                            src={
                              cards?.[1]?.fields.mobileAnimationAsset?.fields
                                ?.assetId
                            }
                            alt={
                              cards?.[1]?.fields.mobileAnimationAsset?.fields
                                ?.alternateText
                            }
                          />
                        </div>
                      ) : (
                        <Image
                          className={'start-guide-animation-asset'}
                          image={cards?.[1]?.fields.animationAsset}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div>
          {cards && cards?.[2] && (
            <div
              className={`start-guide-shuffle-block ${cards?.[2]?.fields.className}`}
              id='start-guide-shuffle-block-third'
            >
              <Image
                className={'start-guide-background'}
                image={
                  checkIsMobile()
                    ? mobileBackgroundAsset
                    : desktopBackgroundAsset
                }
              />

              <div className={'start-guide-content-container'}>
                {cards?.[2]?.fields.animationImagePosition ===
                  StartGuideBlockConstants.left && (
                  <div id='start-guide-shuffle-block-img-second'>
                    {checkIsMobile() ? (
                      <div className='ob-mainAsset-wrapper start-guide-animation-asset'>
                        <img
                          className='ob-mainAsset-wrapper-img'
                          src={
                            cards?.[1]?.fields.mobileAnimationAsset?.fields
                              ?.assetId
                          }
                          alt={
                            cards?.[1]?.fields.mobileAnimationAsset?.fields
                              ?.alternateText
                          }
                        />
                      </div>
                    ) : (
                      <Image
                        className={'start-guide-animation-asset'}
                        image={cards?.[1]?.fields.animationAsset}
                      />
                    )}
                  </div>
                )}

                {
                  <div
                    className={`${cards?.[2]?.fields.className}`}
                    id='start-guide-shuffle-block-third-txt'
                  >
                    {renderTextContent(cards?.[2]?.fields)}

                    {cards?.[2]?.fields.animationImagePosition ===
                      StartGuideBlockConstants.right && (
                      <div>
                        {checkIsMobile() ? (
                          <div className='ob-mainAsset-wrapper start-guide-animation-asset'>
                            <img
                              className='ob-mainAsset-wrapper-img'
                              src={
                                cards?.[1]?.fields.mobileAnimationAsset?.fields
                                  ?.assetId
                              }
                              alt={
                                cards?.[1]?.fields.mobileAnimationAsset?.fields
                                  ?.alternateText
                              }
                            />
                          </div>
                        ) : (
                          <Image
                            className={'start-guide-animation-asset'}
                            image={cards?.[2]?.fields.animationAsset}
                          />
                        )}
                      </div>
                    )}
                  </div>
                }
              </div>
            </div>
          )}
        </div>
      </>
    )
  }

  // Animation Block Ends Here
  return (
    <div className={className ? className : null} id={anchorId ? anchorId : ''}>
      {!isAnimationCards ? (
        <>
          {cards &&
            cards.map((cardOption, index) => {
              const option = cardOption?.fields
              return renderScreenLayout(option)
            })}
        </>
      ) : (
        <>{cards && <>{renderAnimationLayout()}</>}</>
      )}
        {
          <Modal
            isOpen={isModalOpen}
            closeTimeoutMS={250}
            style={customStyleSaveSettingsModal}
            overlayClassName={'event_button_click'}
          >
            {
              <div className= {`Modal-Container ${popupContent?.fields?.className}`}>
                <Image image={popupContent?.fields?.icons} />
                <div className='text-content'>
                  <div
                    className='title'
                    dangerouslySetInnerHTML={{
                      __html: popupContent?.fields?.title,
                    }}
                  ></div>
                  <div
                    className='description'
                    dangerouslySetInnerHTML={{
                      __html: popupContent?.fields?.description,
                    }}
                  ></div>
                  <div
                    className='subtitle'
                    dangerouslySetInnerHTML={{
                      __html: popupContent?.fields?.subTitle,
                    }}
                  ></div>
                </div>
                <button
              className='event_close_window ob-modal-close gum-bleed-close-button'
              onClick={closeModal}
            >
              <Icon name={Constants.close} size='2.4' color='#FFFFFF' />
            </button>
              </div>
            }
            
          </Modal>
        }
    </div>
  )
}

export default mediaQueryHOC(StartGuideShuffleBlock)
