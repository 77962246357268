import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'
import { isObjectEmpty } from '../../adapters/helpers/Utils'
import Block from '../../adapters/helpers/Block'
import dynamic from 'next/dynamic'
const DropDownSelector = dynamic(() => import('../DropDownSelector/DropDownSelector'))
import { MetaConstants } from '../../adapters/helpers/Constants'
import { useTabContext } from '../TabContext/TabContext'

const SwitchTab = (props) => {
    const document = props?.document
    const contentDetails = document?.fields?.contentDetails
    const [selectedSwitchTypeIndex, setSelectedSwitchTypeIndex] = useState(0)
    const { activeTabIndex, setActiveTabIndex } = useTabContext()
    const hasAllProducts = document?.fields?.hasAllProducts
    const isDropDown = document?.fields?.isDropDown
    const anchorId = document?.fields?.anchorId
    const className = document?.fields?.classNames
    const isMultipleTabs = document?.fields?.isMultipleTabs
    useEffect(() => {
        if (props && props.changeTabIndex) {
            setSelectedSwitchTypeIndex(props.changeTabIndex)
            setActiveTabIndex(props.changeTabIndex)
        } else {
            setSelectedSwitchTypeIndex(0)
            setActiveTabIndex(0)
        }
    }, [props.changeTabIndex])
    let switchType
    switchType = Array.isArray(contentDetails) && contentDetails.length > (hasAllProducts ? selectedSwitchTypeIndex : activeTabIndex) && contentDetails[hasAllProducts ? selectedSwitchTypeIndex : activeTabIndex]
    if (hasAllProducts && isMultipleTabs && !isDropDown) {
        switchType = Array.isArray(contentDetails) && contentDetails.length > selectedSwitchTypeIndex && contentDetails[selectedSwitchTypeIndex]
    }
    if (!isDropDown) {
        switchType = Array.isArray(contentDetails) && contentDetails.length > selectedSwitchTypeIndex && contentDetails[selectedSwitchTypeIndex]
    }
    let contents = switchType?.fields?.contents

    const handleSwitchTypeChange = (index) => {
        setSelectedSwitchTypeIndex(index)
        setActiveTabIndex(index)
        if (props?.carouselSwitchTab) {
            props?.handleSwitchTabCallBack(index)
        }
    }

    for (let i = 0; i < contents?.length; i++) {
        if (contents?.[i]?.fields?.contentType === 'switchTabs') {
            if (!contents[i].fields.onClickCallback && !props?.carouselSwitchTab) {
                contents[i].fields.onClickCallback = props?.onClickCallback
            } else if (!contents[i].fields.onClickCallback && props?.carouselSwitchTab) {
                contents[i].fields.onClickCallback = props?.carouselProp?.onClickCallback
            }
        } else if (contents?.[i]?.fields?.contentType === 'productRecommendBlock') {
            if (!contents[i].fields.onClickCallback && !props?.carouselSwitchTab) {
                contents[i].fields.onClickCallback = props?.onClickCallback ? props?.onClickCallback : props?.document?.fields?.onClickCallback
            } else if (!contents[i].fields.onClickCallback && props?.carouselSwitchTab) {
                if (props?.carouselProp?.onClickCallback) contents[i].fields.onClickCallback = props?.carouselProp?.onClickCallback
            }
        }
    }

    return (
        <section className={`ob-switch-tab-block ${className ? className : ''}`} id={anchorId}>
            <h3 className='ob-switch-tab-title'>{document?.fields?.title}</h3>
            {isDropDown ? (
                <div className='ob-switch-dropdown-container'>
                    <DropDownSelector options={contentDetails} selectedOption={!hasAllProducts ? activeTabIndex : selectedSwitchTypeIndex} handleOptionClick={handleSwitchTypeChange} anchorId={anchorId} />
                </div>
            ) : (
                <>
                    {!isMultipleTabs
                        ? Array.isArray(contentDetails) && (
                              <ul role='tablist' className={'ob-switch-tab__content-details'}>
                                  {contentDetails &&
                                      contentDetails.map((switchtab, index) => {
                                          const swtichTypeCornerClass = index === 0 ? 'ob-switch-tab__content-details-left' : index === contentDetails.length - 1 ? 'ob-switch-tab__content-details-right' : ''
                                          return (
                                              <li role='tab' key={index}>
                                                  <button
                                                      className={`ob-switch-tab__content-details-type event_button_click ${index === selectedSwitchTypeIndex ? 'ob-switch-tab__active-switch' : ''} ${swtichTypeCornerClass}`}
                                                      onClick={() => setSelectedSwitchTypeIndex(index)}
                                                      key={index}
                                                      tabIndex={index}
                                                      data-action-detail={switchtab?.fields?.label}
                                                  >
                                                      {switchtab?.fields?.label}
                                                  </button>
                                              </li>
                                          )
                                      })}
                              </ul>
                          )
                        : Array.isArray(contentDetails) && (
                              <ul className={'ob-switch-tab-multiple__content-details'}>
                                  {contentDetails &&
                                      contentDetails.map((switchtab, index) => {
                                          return (
                                              <li key={index}>
                                                  <a
                                                      className={`ob-switch-tab__content-details-type event_button_click ${index === selectedSwitchTypeIndex ? 'ob-switch-tab__active-switch' : ''}`}
                                                      onClick={() => setSelectedSwitchTypeIndex(index)}
                                                      onKeyPress={() => setSelectedSwitchTypeIndex(index)}
                                                      key={index}
                                                      tabIndex={0}
                                                      data-action-detail={switchtab?.fields?.label}
                                                  >
                                                      <img alt={`Tab- ${switchtab?.fields?.label}`} className='switch-tab-image-asset' src={MetaConstants.https + switchtab?.fields?.switchTabAsset?.fields?.file?.url} />
                                                      <div className='switch-tab-label'>{switchtab?.fields?.label}</div>
                                                  </a>
                                                  <div className={`switch-tab-bottom-arrow ${index === selectedSwitchTypeIndex ? 'active' : ''}`} />
                                              </li>
                                          )
                                      })}
                              </ul>
                          )}
                </>
            )}
            {!isObjectEmpty(contents) && new Block(contents).renderSpecifiedBlock(contents)}
        </section>
    )
}

SwitchTab.propTypes = {
    document: PropTypes.object,
}

export default mediaQueryHOC(SwitchTab)
