import React from 'react';
import PropTypes from 'prop-types';
import {YoutubeVideoConstants} from '../../adapters/helpers/Constants';

const DEFAULT_ASPECT_RATIO = 16/9;

export default function YoutubeVideo({document}) {
    const videoId = document?.fields?.videoId || document?.videoId
    const aspectRatio = document?.fields?.aspectRatio || document?.aspectRatio || DEFAULT_ASPECT_RATIO
    const classNames = document?.fields?.classNames || document?.classNames || ''

    return (<>
        {!!videoId &&
            <div className={classNames}>
                <div className="ob-youtube-video-block" style={{paddingBottom: (100/ aspectRatio).toFixed(3) + YoutubeVideoConstants.percent}}>
                    <iframe loading='lazy' title={YoutubeVideoConstants.title} src={`${YoutubeVideoConstants.url}${videoId}`}></iframe>
                </div>
            </div>
        }
    </>)
}

YoutubeVideo.propTypes = {
    document: PropTypes.object,
}